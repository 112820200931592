import { TBrand } from '../Brands/types'

export enum EProduct {
  Id = 'id',
  Type = 'type',
  Provider = 'provider',
  ArName = 'arName',
  EnName = 'enName',
  BrandId = 'brandId',
  Brand = 'brand',
  ArRedeemInstructions = 'arRedeemInstructions',
  EnRedeemInstructions = 'enRedeemInstructions',
  Logo = 'logo',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  BrandEnNameLike = 'brandEnNameLike',
  IsActive = 'isActive',
  Order = 'order',
  MarketPlaceCount = 'marketplaceCount',
}

export enum EType {
  BUNDLE = 'BUNDLE',
  VOUCHER = 'VOUCHER',
  TOPUP = 'TOPUP',
}

export enum EProvider {
  ASIACELL = 'ASIACELL',
  MINTROUTE = 'MINTROUTE',
  DEFAULT = 'DEFAULT',
  KOREK_BUNDLES = 'KOREK BUNDLES',
  KOREK_VOUCHERS = 'KOREK VOUCHERS',
  ALSAREE3 = 'ALSAREE3',
  LIKECARD = 'LIKECARD',
  NOJOOM_ALRABIAA = 'NOJOOM ALRABIAA',
  ALWATANI_TOPUP = 'ALWATANI TOPUP',
}

export type TProduct = {
  [EProduct.Id]: number
  [EProduct.Type]: EType
  [EProduct.Provider]: EProvider
  [EProduct.ArName]: string
  [EProduct.EnName]: string
  [EProduct.BrandId]: number
  [EProduct.Brand]: TBrand
  [EProduct.ArRedeemInstructions]: string
  [EProduct.EnRedeemInstructions]: string
  [EProduct.Logo]: string
  [EProduct.CreatedAt]: Date
  [EProduct.UpdatedAt]: Date
  [EProduct.Order]: number
}

export const activeStatusList = [
  { id: true, name: 'Active' },
  { id: false, name: 'Inactive' },
]
