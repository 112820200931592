export enum EBrand {
  Id = 'id',
  EnName = 'enName',
  ArName = 'arName',
  EnDescription = 'enDescription',
  ArDescription = 'arDescription',
  Logo = 'logo',
  ShowInHome = 'showInHome',
  Order = 'order',
  ProductsCount = 'productsCount',
  SubCategoryId = 'subCategoryId',
  IsActive = 'isActive',
  IsGlobal = 'isGlobal',
  Ids = 'ids',
  MarketPlaceCount = 'marketplaceCount',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export type TBrand = {
  [EBrand.Id]: number
  [EBrand.EnName]: string
  [EBrand.ArName]: string
  [EBrand.EnDescription]: string
  [EBrand.ArDescription]: string
  [EBrand.Logo]: string
  [EBrand.ShowInHome]: boolean
  [EBrand.Order]: number
  [EBrand.ProductsCount]: number
  [EBrand.SubCategoryId]?: number
  [EBrand.IsActive]: boolean
}

export const activeStatusList = [
  { id: true, name: 'Available' }, // "Available" maps to `true`
  { id: false, name: 'Not Available' }, // "Not Available" maps to `false`
]
