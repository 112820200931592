import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  WithRecord,
  FormDataConsumer,
  useGetRecordId,
  Button,
} from 'react-admin'
import { EAdmin } from './types'
import { EBrand } from '../Brands/types'
import { adminsEditChoices } from './consts'
import { ERole, ERoleRequest, Permission, PermissionsGroup } from '@/types/data'
import { requiredTrimmed } from '@/utils/validation'
import { Alert, AlertTitle, Box, Divider, Skeleton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { axiosAuthInstance } from '@/services/api/axios'
import { SubjectPermissions } from '@/components/SubjectPermissions'
import { API_AUTH_BASE_URL } from '@/services/api/consts'

type Ability = {
  permission: Permission
  can: boolean
}

type admin = {
  id: string
  username: string
  deleted: boolean
  isActive: boolean
  role: string
  brandIds: string[]
  merchantId: string
  createdAt: string
  updatedAt: string
}

export enum PermissionType {
  Inherited = 'Inherited',
  Extended = 'Extended',
  Denied = 'Denied',
  Default = 'Default',
}

export const AdminsEdit = () => {
  const adminId = useGetRecordId()
  const [adminInfo, setAdminInfo] = useState<admin | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [userPermissions, setUserPermissions] = useState<Permission[]>([])
  const [permissionsGroups, setPermissionsGroups] = useState<Array<PermissionsGroup>>([])
  const [abilities, setAbilities] = useState<Array<Ability>>([])
  const [rolePermissions, setRolePermissions] = useState<Permission[]>([])
  const [showBox, setShowBox] = useState(false)

  const fetchPermissions = async () => {
    try {
      const response = await axiosAuthInstance.get(`${API_AUTH_BASE_URL}/dashboard/admins/${adminId}/permissions`)
      const { userPermissions, permissionsGroups } = response.data

      const rolePermissionResponse = await axiosAuthInstance.get(
        `${API_AUTH_BASE_URL}/dashboard/auth/roles/${adminInfo?.role}/permissions`,
      )
      const { rolePermissions } = rolePermissionResponse.data
      setRolePermissions(rolePermissions)

      setUserPermissions(userPermissions)
      setPermissionsGroups(permissionsGroups)

      // Maintain toggled abilities even after fetching permissions
      setAbilities((prevAbilities) => {
        const abilitiesMap = new Map(prevAbilities.map((ab) => [ab.permission.subject + ab.permission.action, ab]))
        const updatedAbilities = userPermissions.map((perm: Permission) => {
          const key = perm.subject + perm.action
          return abilitiesMap.has(key) ? { ...abilitiesMap.get(key)!, can: true } : { permission: perm, can: true }
        })
        return updatedAbilities
      })
    } catch (err) {
      console.error('Error fetching permissions:', err)
      setError((err as Error).message)
    } finally {
      setLoading(false)
    }
  }

  function getPermissionType(permission: Permission, checked: boolean): PermissionType {
    const rolePermission = rolePermissions.find(
      (perm) => perm.subject === permission.subject && perm.action === permission.action,
    )

    if (rolePermission && checked) {
      return PermissionType.Inherited
    } else if (rolePermission && !checked) {
      return PermissionType.Denied
    } else if (!rolePermission && !checked) {
      return PermissionType.Default
    } else {
      return PermissionType.Extended
    }
  }

  const handleTogglePermission = (permission: Permission, isToggled: boolean) => {
    setAbilities((prevAbilities) => {
      const abilitiesMap = new Map(prevAbilities.map((ab) => [ab.permission.subject + ab.permission.action, ab]))
      const key = permission.subject + permission.action

      if (isToggled) {
        abilitiesMap.set(key, { permission, can: true })
      } else {
        abilitiesMap.delete(key)
      }

      return Array.from(abilitiesMap.values())
    })

    setUserPermissions((prevUserPermissions) => {
      if (isToggled) {
        return [...prevUserPermissions, permission]
      } else {
        return prevUserPermissions.filter(
          (perm) => perm.subject !== permission.subject || perm.action !== permission.action,
        )
      }
    })
  }

  const handleCheckAbility = (permission: Permission) => {
    return userPermissions.some((perm) => perm.subject === permission.subject && perm.action === permission.action)
  }

  useEffect(() => {
    const fetchAdminInfo = async () => {
      try {
        const response = await axiosAuthInstance.get(`${API_AUTH_BASE_URL}/dashboard/admins/${adminId}`)
        setAdminInfo(response.data)
      } catch (err) {
        console.error('Error fetching permissions:', err)
        setError((err as Error).message)
      }
    }
    fetchAdminInfo()
  }, [])

  useEffect(() => {
    if (adminInfo) {
      fetchPermissions()
    }
  }, [adminInfo])

  const save = async () => {
    try {
      await axiosAuthInstance.put(`${API_AUTH_BASE_URL}/dashboard/admins/${adminId}`, {
        role: adminInfo?.role,
        abilities: abilities,
        brandIds: adminInfo?.brandIds,
        isActive: adminInfo?.isActive,
        ...(adminInfo?.merchantId ? { merchantId: adminInfo.merchantId } : {}),
      })
      fetchPermissions()
    } catch (err) {
      console.error('Error saving permissions:', err)
      setError((err as Error).message)
    }
  }
  if (loading)
    return (
      <Box>
        <Skeleton />
        <Skeleton animation="pulse" />
        <Skeleton animation={false} />
      </Box>
    )
  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    )

  return (
    <>
      <Edit redirect="list">
        <SimpleForm sanitizeEmptyValues display="flex" justifyContent="center" alignItems="start" onSubmit={save}>
          <FormDataConsumer>
            {({ formData }) => {
              switch (formData.role) {
                case ERole.Admin:
                case ERoleRequest.Admin:
                case ERole.Superadmin:
                case ERoleRequest.Superadmin:
                case ERoleRequest.TZadmin:
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <SelectInput
                        source={EAdmin.Role}
                        choices={adminsEditChoices}
                        validate={required()}
                        optionValue="name"
                      />
                    </div>
                  )
                case ERole.Merchant:
                case ERoleRequest.Merchant:
                  return (
                    <TextInput source={EAdmin.MerchantId} label="Merchant Id" validate={requiredTrimmed} required />
                  )
                case ERole.Supplier:
                case ERoleRequest.Supplier:
                  return (
                    <WithRecord
                      render={(record) => (
                        <ReferenceArrayInput source={EAdmin.BrandIds} reference="brands" perPage={100}>
                          <AutocompleteArrayInput
                            optionText={EBrand.EnName}
                            defaultValue={record[EAdmin.BrandIds]}
                            validate={required()}
                          />
                        </ReferenceArrayInput>
                      )}
                    />
                  )
                default:
                  return <div />
              }
            }}
          </FormDataConsumer>

          <BooleanInput source={EAdmin.IsActive} />
        </SimpleForm>
      </Edit>
      <Divider />
      <Box>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '50px',
            paddingBottom: '20px',
            paddingRight: '20px',
            paddingLeft: '20px',
          }}
        >
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Permissions Management
          </Typography>

          <Button
            onClick={save}
            label="Save"
            variant="contained"
            sx={{
              margin: 0,
              fontSize: '12px',
              paddingRight: '6px',
              paddingLeft: '6px',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderColor: 'primary.main',
              color: 'primary',
              '&:hover': {
                borderColor: 'primary.dark',
                color: 'primary.dark',
                backgroundColor: 'primary.light',
              },
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          />
        </div>
        <Box>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
              fontSize: '12px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '20px',
            }}
            onClick={() => setShowBox(!showBox)}
          >
            Note: Click Here!
          </Typography>
          {showBox && (
            <Box>
              <Typography variant="body2" style={{ color: 'green' }}>
                <span
                  style={{
                    width: '50px',
                    padding: '2px 6px',
                    fontSize: '8px',
                    borderRadius: '4px',
                    backgroundColor: 'green',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Inherited:
                </span>{' '}
                Permissions that are inherited from the role assigned to the admin.
              </Typography>
              <Typography variant="body2" style={{ color: 'blue' }}>
                <span
                  style={{
                    width: '50px',
                    padding: '2px 6px',
                    fontSize: '8px',
                    borderRadius: '4px',
                    backgroundColor: 'black',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Extended:
                </span>{' '}
                Permissions that are explicitly granted to the admin in addition to the inherited ones.
              </Typography>
              <Typography variant="body2" style={{ color: 'red' }}>
                <span
                  style={{
                    width: '50px',
                    padding: '2px 6px',
                    fontSize: '8px',
                    borderRadius: '4px',
                    backgroundColor: 'red',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Denied:
                </span>{' '}
                Permissions that are explicitly denied to the admin, overriding inherited permissions.
              </Typography>
              <Typography variant="body2" style={{ color: 'gray' }}>
                <span
                  style={{
                    width: '50px',
                    padding: '2px 6px',
                    fontSize: '8px',
                    borderRadius: '4px',
                    backgroundColor: 'gray',
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Default:
                </span>{' '}
                Permissions that are neither granted nor denied explicitly.
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexWrap="wrap">
          {permissionsGroups.map((group, index) => (
            <SubjectPermissions
              key={index}
              subjectTitle={group.subjectTitle}
              permissions={group.permissions}
              handlePermissionToggle={handleTogglePermission}
              checkAbility={handleCheckAbility}
              getPermissionType={getPermissionType}
            />
          ))}
        </Box>
      </Box>
    </>
  )
}
