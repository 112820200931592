import { apiSettingsFetch, apiSettingsChange } from '@/services/api/methods/apiSettings'
import { TSettings } from '@/types/data'
import { FormControlLabel, Snackbar, Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save'
import { Loading, Title } from 'react-admin'
import isEqual from 'lodash/isEqual'
import LoadingButton from '@mui/lab/LoadingButton'
import { Can } from '@/context/AbilityContext'

export const Settings = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSaveSuccess, setIsSaveSuccess] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [initialSettings, setInitialSettings] = useState<TSettings>()
  const [settings, setSettings] = useState<TSettings>()

  const onHideGlobalBrandsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => ({ ...prev, hideGlobalBrands: event.target.checked }))
  }

  const onSave = async () => {
    try {
      setIsSaveSuccess(false)
      setIsSaveLoading(true)
      await apiSettingsChange(settings as TSettings)
      setIsSaveSuccess(true)
      setInitialSettings(settings)
      setIsDirty(false)
    } finally {
      setIsSaveLoading(false)
    }
  }

  useEffect(() => {
    const fetchSettings = async () => {
      setIsLoading(true)
      const data = await apiSettingsFetch()
      setInitialSettings(data)
      setSettings(data)
      setIsLoading(false)
    }

    fetchSettings()
  }, [])

  useEffect(() => {
    if (!isEqual(initialSettings, settings)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [settings])

  return (
    <Can I="findAll" a="settings">
      <Title title="Settings" />
      {isLoading && <Loading />}
      <FormControlLabel
        control={<Switch checked={settings?.hideGlobalBrands} onChange={onHideGlobalBrandsChange} />}
        label="Hide Global Brands"
        style={{ marginBottom: '20px' }}
      />
      <Can I="update" a="settings">
        <div>
          <LoadingButton
            disabled={!isDirty || isSaveLoading}
            onClick={onSave}
            endIcon={<SaveIcon />}
            loading={isSaveLoading}
            loadingPosition="end"
            variant="contained"
          >
            <span>Save</span>
          </LoadingButton>
        </div>
      </Can>
      <Snackbar
        onClose={() => setIsSaveSuccess(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={isSaveSuccess}
        message="Settings saved"
      />
    </Can>
  )
}
