export const TOKEN_KEY = 'token'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const SESSION_KEY = 'session'
export const ADMIN_ID_KEY = 'ADMIN_ID'
export const PERMISSIONS_KEY = 'permission'
export const API_AUTH_BASE_URL = process.env.VITE_API_AUTH_BASE_URL
export const CUSTOM_JSON_HEADERS = {
  'Content-Type': 'application/json',
  accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
}
