export enum ESortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum EQueryParam {
  Offset = 'offset',
  Limit = 'limit',
  SortBy = 'sortBy',
  SortingDirection = 'sortingDirection',
}

export enum ERole {
  Admin = 'admin',
  Merchant = 'merchant',
  Supplier = 'supplier',
  Superadmin = 'SUPER_ADMIN',
}

export enum ERoleRequest {
  Admin = 'ADMIN',
  Merchant = 'MERCHANT',
  Supplier = 'SUPPLIER',
  Superadmin = 'SUPER_ADMIN',
  TZadmin = 'TZ_ADMIN',
  EXTERNAL_ENTITY = 'EXTERNAL_ENTITY',
}

export type TMeta = {
  totalCount: number
  page?: number
  perPage?: number
}

export type TSettings = {
  hideGlobalBrands: boolean
}

export type Permission = {
  action: string
  subject: string
}

export type PermissionsGroup = {
  subjectTitle: string
  permissions: Permission[]
}
