import { Box } from '@mui/material'
import {
  BooleanField,
  BulkDeleteButton,
  BulkExportButton,
  ChipField,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput,
  WithRecord,
  BooleanInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  FilterButton,
} from 'react-admin'

import { tokens } from '@/Layout/theme'
import { EPromoCodeType, EPromocode } from '@/modules/Promocodes/types'
import { EValue, EVariant } from '@/modules/Variants/types'
import { EBrand } from '@/modules/Brands/types'
import { EProduct } from '@/modules/Products/types'
import { EResource } from '@/admin/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

const promocodesFilters = [
  <TextInput source={EPromocode.CodeLike} label="Search (code)" alwaysOn key={1} />,
  <NumberInput source="ids" label="Search (id)" alwaysOn key={2} />,
  <BooleanInput
    label="Is Active"
    source={EPromocode.IsActive}
    defaultValue="true"
    key={3}
    sx={{ margin: '10px 12px' }}
  />,
]

export const PromocodesList = () => {
  const colors = tokens()

  function GetVariantText(record: any): string | null {
    if (record && record.value && record.value.length > 0) {
      const value = record[EVariant.Value][0]
      const readableEnValue = value[EValue.EnValue] || value[EValue.EnName]
      const readableArValue = value[EValue.ArValue] || value[EValue.ArName]

      const result = `Id: ${record[EVariant.Id]} |  [VALUE] "${readableEnValue}" - "${readableArValue}"`
      return result
    }

    return null
  }

  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      <Can I="addPromoCode" a="promo-codes">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )

  return (
    <Can I="findAllPromoCodes" a="promo-codes">
      <List filters={ability.can('findOnePromoCode', 'promo-codes') ? promocodesFilters : []} actions={<ListActions />}>
        <Datagrid
          rowClick={ability.can('updatePromoCode', 'promo-codes') ? 'edit' : false}
          bulkActionButtons={
            <>
              <BulkExportButton />
              <Can I="removePromoCode" a="promo-codes">
                <BulkDeleteButton />
              </Can>
            </>
          }
        >
          <TextField source={EPromocode.Id} label="ID" />
          <TextField source={EPromocode.Code} sortable={false} />
          <NumberField source={EPromocode.Value} textAlign="left" sortable={false} />
          <ChipField
            sx={{
              backgroundColor: colors.accent.pink,
              color: colors.neutral[0],
            }}
            source={EPromocode.ValueType}
            label="Value type"
          />
          <NumberField source={EPromocode.MaxUsage} textAlign="left" />
          <DateField source={EPromocode.ExpiryDate} />
          <WithRecord
            label="Consumer Phones"
            render={(record) => (
              <Box padding={'0'}>
                {record[EPromocode.ConsumerPhones].map((item: string) => (
                  <p key={item} style={{ margin: '3px 0' }}>
                    {item}
                  </p>
                ))}
              </Box>
            )}
          />
          <DateField source={EPromocode.MaxRegisterDate} />
          <WithRecord
            label="Promocode Type"
            render={(record) => {
              switch (record[EPromocode.PromoCodableType]) {
                case EPromoCodeType.BRAND: {
                  return (
                    <ChipField
                      sx={{
                        backgroundColor: colors.accent.sky,
                        color: colors.neutral[0],
                      }}
                      source={EPromocode.PromoCodableType}
                      label="Promocode type"
                    />
                  )
                }
                case EPromoCodeType.PRODUCT: {
                  return (
                    <ChipField
                      sx={{
                        backgroundColor: colors.accent.mint,
                        color: colors.neutral[0],
                      }}
                      source={EPromocode.PromoCodableType}
                      label="Promocode type"
                    />
                  )
                }
                case EPromoCodeType.VARIANT: {
                  return (
                    <ChipField
                      sx={{
                        backgroundColor: colors.accent.coral,
                        color: colors.neutral[0],
                      }}
                      source={EPromocode.PromoCodableType}
                      label="Promocode type"
                    />
                  )
                }
                default: {
                  return <p>unknown promocode type</p>
                }
              }
            }}
          />

          <WithRecord
            label="Promocode Detail"
            render={(record) => {
              switch (record[EPromocode.PromoCodableType]) {
                case EPromoCodeType.BRAND: {
                  return (
                    <ReferenceArrayField
                      source={EPromocode.PromoCodableIds}
                      reference={EResource.Brands}
                      sortable={false}
                    >
                      <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px' }}>
                        <TextField source={EBrand.EnName} />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  )
                }
                case EPromoCodeType.PRODUCT: {
                  return (
                    <ReferenceArrayField
                      source={EPromocode.PromoCodableIds}
                      reference={EResource.Products}
                      sortable={false}
                    >
                      <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px' }}>
                        <TextField source={EProduct.EnName} />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  )
                }
                case EPromoCodeType.VARIANT: {
                  return (
                    <ReferenceArrayField
                      source={EPromocode.PromoCodableIds}
                      reference={EResource.Variants}
                      sortable={false}
                    >
                      <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px' }}>
                        <WithRecord
                          label="Type"
                          render={(record) => <span>{record ? GetVariantText(record) : 'sssss'}</span>}
                        />
                      </SingleFieldList>
                    </ReferenceArrayField>
                  )
                }
                default: {
                  return <p>unknown promocode type</p>
                }
              }
            }}
          />
          <BooleanField source={EPromocode.IsActive} sortable={false} />
          <BooleanField source={EPromocode.NeverActiveUsers} sortable={false} />
          <DateField source={EPromocode.CreatedAt} label="Created At" />
        </Datagrid>
      </List>
    </Can>
  )
}
