import {
  ArrayField,
  List,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  WrapperField,
  BulkExportButton,
  BulkDeleteButton,
  BooleanField,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'

import { Box } from '@mui/material'
import { EMainCategory } from '@/modules/MainCategories/types'
import { ESortOrder } from '@/types/data'
import { ESubCategory } from '@/modules/SubCategories/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

const ListActions = () => (
  <TopToolbar>
    <Can I="createMainCategory" a="main-categories">
      <CreateButton />
    </Can>
    <ExportButton />
  </TopToolbar>
)

export const MainCategoriesList = () => (
  <Can I="findAll" a="main-categories">
    <List sort={{ field: EMainCategory.Order, order: ESortOrder.Asc }} actions={<ListActions />}>
      <Datagrid
        rowClick={ability.can('updateMainCategory', 'main-categories') ? 'edit' : false}
        bulkActionButtons={
          <>
            <BulkExportButton />
            <Can I="deleteMainCategory" a="main-categories">
              <BulkDeleteButton />
            </Can>
          </>
        }
      >
        <NumberField source={EMainCategory.Id} textAlign="left" />

        <WrapperField label="Category" sortBy={EMainCategory.EnName}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ImageField
              source={EMainCategory.Logo}
              sortable={false}
              sx={{
                '& img': {
                  maxWidth: 50,
                  maxHeight: 50,
                  objectFit: 'cover !important',
                  borderRadius: '50%',
                },
                display: 'flex',
                marginRight: '8px',
              }}
            />
            <TextField source={EMainCategory.EnName} label="English Name" />
          </Box>
        </WrapperField>
        <TextField source={EMainCategory.ArName} label="Arabic Name" sortBy="arName" />
        <BooleanField source={EMainCategory.IsActive} sortable={false} />
        <NumberField source={EMainCategory.Order} sortable />

        <ArrayField
          source={EMainCategory.SubCategories}
          sortable={false}
          sort={{ field: ESubCategory.Order, order: ESortOrder.Asc }}
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source={ESubCategory.Id} sortable={false} />
            <TextField source={ESubCategory.EnName} label="English Name" sortable={false} />
            <TextField source={ESubCategory.ArName} label="Arabic Name" sortable={false} />
            <NumberField source={ESubCategory.Order} sortable={false} />
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </List>
  </Can>
)
