import {
  List,
  Datagrid,
  NumberField,
  DateField,
  TextField,
  SearchInput,
  BulkExportButton,
  ReferenceField,
  WithRecord,
  Button,
  useNotify,
  Identifier,
  SelectInput,
} from 'react-admin'

import { tokens } from '@/Layout/theme'
import { LabelField } from '@/components/LabelField'
import { ETicketOrder } from '@/modules/TicketOrders/types'
import { EMatch } from '@/modules/Matches/types'

import { postResendSms } from '@/services/api/methods/postResendSMS'
import SMSIcon from '@mui/icons-material/SmsOutlined'
import { ESortOrder } from '@/types/data'
import { EStatus } from '@/modules/Orders/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

const ticketsFilters = [
  <SearchInput source={ETicketOrder.OrderNumber} alwaysOn key={1} placeholder="Search (order number)" />,
  <SearchInput source={ETicketOrder.MinTotalPrice} alwaysOn key={2} placeholder="Min price" type="number" />,
  <SearchInput source={ETicketOrder.MaxTotalPrice} alwaysOn key={3} placeholder="Max price" type="number" />,
  <SearchInput source={ETicketOrder.MatchId} key={4} placeholder="Search (match Id)" type="number" />,
  <SearchInput source={ETicketOrder.UserId} key={5} placeholder="Search (user Id)" />,
  <SearchInput source={ETicketOrder.TransactionId} key={6} placeholder="Search (transaction Id)" />,
  <SelectInput
    source={ETicketOrder.Status}
    key={7}
    placeholder="Search (order status)"
    choices={Object.keys(EStatus).map((key) => ({ id: key, name: key }))}
  />,
]

export const TicketOrdersList = () => {
  const colors = tokens()
  const notify = useNotify()

  const handleResendSms =
    ({ id }: { id: Identifier }) =>
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      postResendSms(id, true, notify)
    }

  return (
    <Can I="getAllTicketOrders" a="ticket-orders">
      <List
        filters={ability.can('getTicketOrder', 'ticket-orders') ? ticketsFilters : []}
        sort={{ field: ETicketOrder.CreatedAt, order: ESortOrder.Desc }}
      >
        <Datagrid
          rowClick={ability.can('getTicketOrder', 'ticket-orders') ? 'show' : false}
          bulkActionButtons={<BulkExportButton />}
        >
          <NumberField source={ETicketOrder.Id} textAlign="left" sortable />
          <TextField source={ETicketOrder.OrderNumber} sortable={false} label="Order Number" />
          <NumberField source={ETicketOrder.TotalPrice} textAlign="left" sortable />
          <TextField source={ETicketOrder.UserId} sortable={false} label="User Id" />
          <TextField source={`${ETicketOrder.User}.phone`} sortable={false} label="User Phone" />
          <LabelField source={ETicketOrder.Status} label="Status" sortable />
          <TextField source={ETicketOrder.TransactionId} sortable={false} label="Transaction Id" />

          <ReferenceField
            source={`${ETicketOrder.Match}.${EMatch.Id}`}
            reference="matches"
            sortable={false}
            label="Match Id"
          >
            <TextField source={EMatch.Id} />
          </ReferenceField>

          <DateField source={ETicketOrder.CreatedAt} sortable />

          <WithRecord
            render={(record) => (
              <Button onClick={handleResendSms(record)} endIcon={<SMSIcon />} variant="outlined">
                <h6
                  style={{
                    margin: '0',
                    fontSize: '12px',
                    borderRight: `1px solid ${colors.cyanosed[400]}`,
                    paddingRight: '6px',
                  }}
                >
                  Resend SMS
                </h6>
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </Can>
  )
}
