import { useEffect, useState, useCallback } from 'react'
import { Alert, AlertTitle, Box, Button, Divider, ListItemText, Skeleton, Typography } from '@mui/material'
import { SubjectPermissions } from '@/components/SubjectPermissions'
import { axiosAuthInstance } from '@/services/api/axios'
import { Permission, PermissionsGroup } from '@/types/data'
import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { Can } from '@/context/AbilityContext'

export type PermissionsState = Record<string, Record<string, boolean>>

export type FormValues = {
  permissionsState: PermissionsState
}

export const RolesAndPermissionsManagementList = () => {
  const [roles, setRoles] = useState<string[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [selectedRole, setSelectedRole] = useState<string>(localStorage.getItem('selectedRole') || 'SUPER_ADMIN')
  const [rolePermissions, setRolePermissions] = useState<Permission[]>([])
  const [permissionsGroups, setPermissionsGroups] = useState<Array<PermissionsGroup>>([])

  const fetchPermissions = useCallback(async () => {
    try {
      const response = await axiosAuthInstance.get(
        `${API_AUTH_BASE_URL}/dashboard/auth/roles/${selectedRole}/permissions`,
      )
      const { rolePermissions, permissionsGroups } = response.data

      setRolePermissions(rolePermissions)
      setPermissionsGroups(permissionsGroups)
    } catch (err) {
      console.error('Error fetching permissions:', err)
      setError((err as Error).message)
    } finally {
      setLoading(false)
    }
  }, [selectedRole])

  useEffect(() => {
    fetchPermissions()
  }, [selectedRole, fetchPermissions])

  const fetchRoles = useCallback(async () => {
    try {
      const response = await axiosAuthInstance.get(`${API_AUTH_BASE_URL}/dashboard/auth/roles`)
      setRoles(response.data)
    } catch (err) {
      console.error('Error fetching roles:', err)
      setError((err as Error).message)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchRoles()
  }, [fetchRoles])

  const handleRoleClick = (role: string) => {
    setSelectedRole(role)
    localStorage.setItem('selectedRole', role)
  }

  const handleTogglePermission = (permission: Permission) => {
    const { subject, action } = permission
    const existedPermission = rolePermissions.find((perm) => perm.subject === subject && perm.action === action)
    if (existedPermission) {
      const updatedPermissions = rolePermissions.filter((perm) => perm.subject !== subject || perm.action !== action)
      setRolePermissions(updatedPermissions)
    } else {
      setRolePermissions([...rolePermissions, permission])
    }
  }

  const handleCheckAbility = (permission: Permission) => {
    return rolePermissions.some((perm) => perm.subject === permission.subject && perm.action === permission.action)
  }

  const save = async () => {
    try {
      await axiosAuthInstance.put(
        `${API_AUTH_BASE_URL}/dashboard/auth/roles/${selectedRole}/permissions`,
        rolePermissions,
      )
    } catch (error) {
      console.error('Error saving permissions:', error)
    }
  }
  if (loading)
    return (
      <Box>
        <Skeleton />
        <Skeleton animation="pulse" />
        <Skeleton animation={false} />
      </Box>
    )
  if (error)
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {error}
      </Alert>
    )

  return (
    <Can I="getRolesPermissions" a="auth">
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignSelf: 'flex-end',
            paddingBottom: '40px',
          }}
        >
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Roles and Permissions Management
          </Typography>
          <Can I="setRolePermissions" a="auth">
            <Button onClick={save} variant="contained" color="primary">
              Save
            </Button>
          </Can>
        </div>
        <div style={{ padding: '10px', display: 'flex', justifyContent: 'flex-start' }}>
          {/* Roles Sidebar */}
          <div>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold' }}>
              Roles
            </Typography>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              {roles.map((role) => (
                <Button
                  key={role}
                  onClick={() => handleRoleClick(role)}
                  sx={{
                    bgcolor: selectedRole === role ? '#f0f0f0' : 'white',
                    color: selectedRole === role ? 'black' : 'gray',
                    borderRadius: '5px',
                    mb: 1,
                    textTransform: 'none',
                    width: '100%',
                    justifyContent: 'flex-start',
                  }}
                >
                  <ListItemText primary={role} />
                </Button>
              ))}
            </div>
          </div>

          <Divider orientation="vertical" flexItem sx={{ height: '100%', mx: 2 }} />

          {/* Permissions Section */}
          <div>
            <Typography variant="h6">Permissions for {selectedRole}</Typography>
            <Box display="flex" flexWrap="wrap">
              {permissionsGroups.map((group, index) => (
                <SubjectPermissions
                  key={index}
                  subjectTitle={group.subjectTitle}
                  permissions={group.permissions}
                  handlePermissionToggle={handleTogglePermission}
                  checkAbility={handleCheckAbility}
                />
              ))}
            </Box>
          </div>
        </div>
      </div>
    </Can>
  )
}
