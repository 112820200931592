import { useState } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ChipField,
  ArrayField,
  NumberField,
  WithRecord,
  WrapperField,
  BooleanField,
  DateField,
  SelectInput,
  required,
  TopToolbar,
  FilterButton,
  ExportButton,
  CreateButton,
  SingleFieldList,
  Button,
  Confirm,
  BulkExportButton,
  BulkDeleteButton,
  ReferenceInput,
} from 'react-admin'
import { Box } from '@mui/material'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import FileUploadIcon from '@mui/icons-material/UploadFile'
import { tokens } from '@/Layout/theme'
import { EVariant, EValue, activeStatusList, reservedList } from '@/modules/Variants/types'
import { EProvider, EType } from '@/modules/Products/types'
import { ImportCSVModal } from '@/components/ImportCSVModal'
import { LabelField } from '@/components/LabelField'
import { providerChoices, typeChoices } from '@/modules/Products/consts'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

export const choices = [
  {
    id: 1,
    name: EType.BUNDLE,
  },
  {
    id: 2,
    name: EType.VOUCHER,
  },
]

const productsFilters = [
  <ReferenceInput
    source={'productId'}
    reference={'products'}
    perPage={1000}
    key={'productId'}
    label="Product Name"
    alwaysOn
  >
    <SelectInput optionText="enName" label="Product Name" />
  </ReferenceInput>,
  <ReferenceInput source={'brandId'} reference={'brands'} perPage={1000} key={'brandId'} label="Brand Name">
    <SelectInput optionText="enName" label="Brand Name" />
  </ReferenceInput>,
  <SelectInput
    label="Provider"
    source={'providerType'}
    key={'providerType'}
    choices={providerChoices}
    optionValue="name"
  />,
  <SelectInput label="Type" source={'productType'} key={'productType'} choices={typeChoices} optionValue="name" />,
  <SelectInput source="isActive" label="Active Status" key="isActive" choices={activeStatusList} />,
  <SelectInput source="isReserved" label="Is Reserved" key="isReserved" choices={reservedList} />,
]

export const VariantsList = () => {
  const [open, setOpen] = useState(false)
  const colors = tokens()

  const handleCloseModal = () => setOpen(false)
  const handleConfirm = () => setOpen(false)

  const VariantsActions = () => (
    <TopToolbar>
      <FilterButton />
      <Can I="createVariant" a="variants">
        <CreateButton />
      </Can>
      <ExportButton />
      <Can I="createVouchersBulk" a="vouchers">
        <Button onClick={() => setOpen(true)} label="Import vouchers from .csv">
          <FileUploadIcon />
        </Button>
      </Can>
    </TopToolbar>
  )

  return (
    <Can I="getAll" a="variants">
      <List filters={productsFilters} actions={<VariantsActions />}>
        <Confirm
          isOpen={open}
          title="Upload CSV"
          content={<ImportCSVModal handleClose={handleCloseModal} />}
          cancel=""
          confirm="Close"
          ConfirmIcon={() => <ErrorOutlinedIcon />}
          CancelIcon={() => null}
          onConfirm={handleConfirm}
          onClose={handleCloseModal}
        />
        <Datagrid
          rowClick={ability.can('updateVariant', 'variants') ? 'edit' : false}
          bulkActionButtons={
            <>
              <BulkExportButton />
              <Can I="deleteVariant" a="variants">
                <BulkDeleteButton />
              </Can>
            </>
          }
        >
          <NumberField source={EVariant.Id} textAlign="left" />
          <WithRecord
            label="Value"
            render={(record) => (
              <ArrayField source={EVariant.Value} sortable={false}>
                <SingleFieldList sx={{ dispaly: 'flex', flexDirection: 'column', padding: '8px 0' }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                      sx={{
                        color: colors.accent.emerald,
                      }}
                      source={record.product.type === EType.BUNDLE ? EValue.EnName : EValue.EnValue}
                    />
                    <TextField
                      sx={{
                        color: colors.accent.pink,
                      }}
                      source={record.product.type === EType.BUNDLE ? EValue.ArName : EValue.ArValue}
                    />
                  </div>
                </SingleFieldList>
              </ArrayField>
            )}
          />
          <WithRecord
            label="Stock"
            render={(record) => (
              <NumberField
                source={EVariant.Stock}
                sortable={false}
                sx={{
                  color: record[EVariant.Stock] === 0 ? colors.redAccent[500] : colors.neutral[900],
                }}
              />
            )}
          />
          <NumberField source={EVariant.Cost} sortable />
          <BooleanField source={EVariant.IsActive} sortable={false} />
          <BooleanField source={EVariant.IsReserved} sortable={false} />
          <TextField
            source={`${EVariant.Product}.${EValue.Brand}.${EValue.EnName}`}
            label="Brand Name"
            sortable={false}
          />
          <WrapperField label="Variant Image" sortable={false}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImageField
                source={`${EVariant.VariantImage}`}
                sx={{
                  '& img': {
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: 'cover !important',
                    borderRadius: '50%',
                  },
                  display: 'flex',
                  marginRight: '8px',
                }}
              />
            </div>
          </WrapperField>
          <NumberField source={EVariant.MarketPlaceCount} label="Activated in Marketplaces" textAlign="left" sortable />
          <NumberField
            source={`${EVariant.Product}.${EValue.Id}`}
            textAlign="left"
            label="Product Id"
            sortable={false}
          />
          <ChipField
            sx={{
              backgroundColor: colors.accent.pink,
              color: colors.neutral[0],
              padding: 0,
            }}
            source={`${EVariant.Product}.${EValue.Type}`}
            label="Type"
            sortable={false}
          />

          <LabelField source={`${EVariant.Product}.${EValue.Provider}`} sortable={false} />
          <WithRecord
            label="Provider Metadata"
            render={(record) => {
              if (record.product.provider === EProvider.ASIACELL) {
                return (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" color={colors.accent.pink}>
                      ID:&nbsp;
                      <NumberField source={`${EVariant.ProviderMetaData}.id`} sortable={false} />
                    </Box>

                    <TextField source={`${EVariant.ProviderMetaData}.name`} sortable={false} />
                  </Box>
                )
              } else if (record.product.provider === EProvider.MINTROUTE) {
                return (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" color={colors.accent.pink}>
                      ID:&nbsp;
                      <TextField source={`${EVariant.ProviderMetaData}.id`} sortable={false} />
                    </Box>
                    <Box display="flex">
                      Min:&nbsp;
                      <NumberField source={`${EVariant.ProviderMetaData}.min`} sortable={false} />
                    </Box>
                    <Box display="flex">
                      Max:&nbsp;
                      <NumberField source={`${EVariant.ProviderMetaData}.max`} sortable={false} />
                    </Box>
                  </Box>
                )
              } else return <div />
            }}
          />

          <NumberField source={EVariant.Order} textAlign="left" sortable />

          <DateField source={EVariant.CreatedAt} sortable={false} />
          <DateField source={EVariant.UpdatedAt} sortable={false} />
        </Datagrid>
      </List>
    </Can>
  )
}
