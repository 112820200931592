/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/default */
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './admin/App'
import * as Sentry from '@sentry/react'
import './index.css'
import { AbilityProvider } from './context/AbilityContext'

Sentry.init({
  dsn: 'https://cdf8ae0ae1b3e4491c518d10da5db08f@o4505543468974080.ingest.sentry.io/4505674598776832',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://dashboard.digitalzone-dev.net/'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AbilityProvider>
      <App />
    </AbilityProvider>
  </React.StrictMode>,
)
