import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  SearchInput,
  TopToolbar,
  CreateButton,
  ExportButton,
  BulkDeleteButton,
} from 'react-admin'

import { EStadium } from '@/modules/Stadiums/types'
import { ESortOrder } from '@/types/data'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

const stadiumsFilters = [
  <SearchInput source={EStadium.ArNameLike} alwaysOn key={1} placeholder="Search (Arabic name)" />,
  <SearchInput source={EStadium.KuNameLike} alwaysOn key={2} placeholder="Search (Kurdish name)" />,
  <SearchInput source={EStadium.ChartKeyLike} alwaysOn key={3} placeholder="Search (Chart key)" />,
]

export const StadiumsList = () => {
  const ListActions = () => (
    <TopToolbar>
      <Can I="create" a="stadiums">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )

  return (
    <Can I="findAll" a="stadiums">
      <List
        filters={ability.can('findOne', 'stadiums') ? stadiumsFilters : []}
        sort={{ field: EStadium.ArName, order: ESortOrder.Asc }}
        actions={<ListActions />}
      >
        <Datagrid
          rowClick={ability.can('findOne', 'stadiums') ? 'edit' : false}
          bulkActionButtons={
            <>
              <Can I="remove" a="stadiums">
                <BulkDeleteButton />
              </Can>
            </>
          }
        >
          <NumberField source={EStadium.Id} textAlign="left" sortable />
          <TextField source={EStadium.ArName} label="Arabic Name" sortable />
          <TextField source={EStadium.KuName} label="Ku Name" sortable />
          <TextField source={EStadium.ChartKey} label="Chart Key" sortable />
          <DateField source={EStadium.CreatedAt} sortable />
        </Datagrid>
      </List>
    </Can>
  )
}
