import { EType, EProvider } from './types'

export const typeChoices = [
  {
    id: EType.BUNDLE,
    name: EType.BUNDLE,
  },
  {
    id: EType.VOUCHER,
    name: EType.VOUCHER,
  },
  {
    id: EType.TOPUP,
    name: EType.TOPUP,
  },
]

export const providerChoices = [
  {
    id: 1,
    name: EProvider.ASIACELL,
  },
  {
    id: 2,
    name: EProvider.MINTROUTE,
  },
  {
    id: 3,
    name: EProvider.DEFAULT,
  },
  {
    id: 4,
    name: EProvider.KOREK_BUNDLES,
  },
  {
    id: 5,
    name: EProvider.KOREK_VOUCHERS,
  },
  {
    id: 6,
    name: EProvider.ALSAREE3,
  },
  {
    id: 7,
    name: EProvider.LIKECARD,
  },
  {
    id: 8,
    name: EProvider.NOJOOM_ALRABIAA,
  },
  {
    id: 9,
    name: EProvider.ALWATANI_TOPUP,
  },
]
