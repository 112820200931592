import {
  List,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceField,
  ImageField,
  BulkExportButton,
  BulkDeleteButton,
  BooleanField,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'
import { Box } from '@mui/material'
import { ESubCategory } from '@/modules/SubCategories/types'
import { EMainCategory } from '@/modules/MainCategories/types'
import { ability } from '@/context/ability'
import { Can } from '@/context/AbilityContext'

export const SubCategoriesList = () => {
  const ListActions = () => (
    <TopToolbar>
      <Can I="createSubCategory" a="sub-categories">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )

  return (
    <Can I="getAllSubcategories" a="sub-categories">
      <List actions={<ListActions />}>
        <Datagrid
          rowClick={ability.can('updateSubCategory', 'sub-categories') ? 'edit' : false}
          bulkActionButtons={
            <>
              <BulkExportButton />
              <Can I="deleteSubCategory" a="sub-categories">
                <BulkDeleteButton />
              </Can>
            </>
          }
        >
          <NumberField source={ESubCategory.Id} textAlign="left" />

          <TextField source={ESubCategory.EnName} label="English Name" />
          <TextField source={ESubCategory.ArName} label="Arabic Name" sortBy="arName" />

          <BooleanField source={EMainCategory.IsActive} sortable={false} />
          <NumberField source={ESubCategory.Order} sortable />

          <ReferenceField
            source={ESubCategory.MainCategoryId}
            reference="main-categories"
            sortable={false}
            link={false}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ImageField
                source={EMainCategory.Logo}
                sx={{
                  '& img': {
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: 'cover !important',
                    borderRadius: '50%',
                  },
                  display: 'flex',
                  marginRight: '8px',
                }}
              />
              <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <TextField source={EMainCategory.EnName} label="English Name" />
                <TextField source={EMainCategory.ArName} label="Arabic Name" sortBy="arName" />
              </Box>
            </Box>
          </ReferenceField>
          <DateField source={ESubCategory.CreatedAt} sortable={false} />
          <DateField source={ESubCategory.UpdatedAt} sortable={false} />
        </Datagrid>
      </List>
    </Can>
  )
}
