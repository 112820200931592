import {
  List,
  Datagrid,
  NumberField,
  DateField,
  TextField,
  BooleanField,
  SearchInput,
  ReferenceField,
  BulkExportButton,
  Button,
  WithRecord,
  Identifier,
  useNotify,
  SelectInput,
} from 'react-admin'

import SMSIcon from '@mui/icons-material/SmsOutlined'

import { tokens } from '@/Layout/theme'

import { ETicket } from '@/modules/Tickets/types'
import { EMatch } from '@/modules/Matches/types'
import { postResendSms } from '@/services/api/methods/postResendSMS'
import { ESortOrder } from '@/types/data'
import { ability } from '@/context/ability'
import { Can } from '@/context/AbilityContext'

const usedChoices = [
  {
    id: 1,
    name: 'Used',
    value: true,
  },
  {
    id: 2,
    name: 'Not used',
    value: false,
  },
]

const ticketsFilters = [
  <SearchInput source={ETicket.UserId} alwaysOn key={1} placeholder="Search (user Id)" />,
  <SearchInput source={ETicket.HolderPhoneLike} alwaysOn key={2} placeholder="Search (Holder Phone)" type="number" />,
  <SearchInput source={ETicket.HolderNameLike} key={3} alwaysOn placeholder="Search (Holder Name)" />,
  <SearchInput source={ETicket.SectorLike} key={4} placeholder="Search (sector)" />,
  <SearchInput source={ETicket.MatchId} key={5} placeholder="Search (match Id)" type="number" />,
  <SearchInput source={ETicket.TicketOrderId} key={6} placeholder="Search (Ticket order Id)" type="number" />,
  <SearchInput source={ETicket.MinPrice} key={7} placeholder="Min price" type="number" />,
  <SearchInput source={ETicket.MaxPrice} key={8} placeholder="Max price" type="number" />,
  <SelectInput
    label="Is used?"
    source={ETicket.Used}
    key={ETicket.Used}
    choices={usedChoices}
    optionValue="value"
    variant="outlined"
  />,
]

export const TicketsList = () => {
  const colors = tokens()
  const notify = useNotify()

  const handleResendSms =
    ({ id }: { id: Identifier }) =>
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      postResendSms(id, false, notify)
    }

  return (
    <Can I="getAllTickets" a="tickets">
      <List
        filters={ability.can('getTicket', 'tickets') ? ticketsFilters : []}
        sort={{ field: ETicket.CreatedAt, order: ESortOrder.Desc }}
      >
        <Datagrid
          rowClick={ability.can('updateTicket', 'tickets') ? 'edit' : false}
          bulkActionButtons={<BulkExportButton />}
        >
          <NumberField source={ETicket.Id} textAlign="left" sortable />
          <TextField source={ETicket.UserId} sortable={false} label="User Id" />

          <ReferenceField source={ETicket.MatchId} reference="matches" sortable={false} label="Match">
            <TextField source={EMatch.Id} />
          </ReferenceField>

          <TextField source={ETicket.Sector} sortable />
          <NumberField source={ETicket.Price} textAlign="left" sortable />
          <BooleanField source={ETicket.Used} sortable />
          <TextField source={ETicket.HolderName} sortable />
          <TextField source={ETicket.HolderPhone} sortable />
          <NumberField source={ETicket.TicketOrderId} textAlign="left" sortable={false} />
          <DateField source={ETicket.CreatedAt} sortable />
          <WithRecord
            render={(record) => (
              <Button onClick={handleResendSms(record)} endIcon={<SMSIcon />} variant="outlined">
                <h6
                  style={{
                    margin: '0',
                    fontSize: '12px',
                    borderRight: `1px solid ${colors.cyanosed[400]}`,
                    paddingRight: '6px',
                  }}
                >
                  Resend SMS
                </h6>
              </Button>
            )}
          />
        </Datagrid>
      </List>
    </Can>
  )
}
