import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ChipField,
  WrapperField,
  DateField,
  BulkExportButton,
  BulkDeleteButton,
  SelectInput,
  BooleanField,
  useRefresh,
  NumberField,
  TopToolbar,
  CreateButton,
  ExportButton,
  FilterButtonMenuItem,
  FilterButton,
  ReferenceInput,
  SearchInput,
} from 'react-admin'
import { Box } from '@mui/material'

import { tokens } from '@/Layout/theme'
import { activeStatusList, EProduct, EProvider, EType } from '@/modules/Products/types'
import { providerChoices, typeChoices } from '@/modules/Products/consts'
import { LabelField } from '@/components/LabelField'
import { EBrand } from '@/modules/Brands/types'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

const productsFilters = [
  <ReferenceInput source={EProduct.BrandEnNameLike} alwaysOn key={1} reference="products">
    <Can I="findOne" a="products">
      <SearchInput source={EProduct.BrandEnNameLike} placeholder="Search (Brand En name)" />
    </Can>
  </ReferenceInput>,
  <ReferenceInput source={EBrand.Ids} reference={'brands'} perPage={1000} key={1} label="Brand Name" alwaysOn>
    <SelectInput optionText="enName" label="Brand Name" />
  </ReferenceInput>,
  <SelectInput
    label="Type"
    source={EProduct.Type}
    defaultValue={EType.BUNDLE}
    key={EProduct.Type}
    choices={typeChoices}
    optionValue="name"
  />,
  <SelectInput
    label="Provider"
    source={EProduct.Provider}
    defaultValue={EProvider.ASIACELL}
    key={EProduct.Provider}
    choices={providerChoices}
    optionValue="name"
  />,
  <SelectInput source="isActive" label="Active Status" key="isActive" choices={activeStatusList} />,
]

export const ProductsList = () => {
  const colors = tokens()
  const refresh = useRefresh()

  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      <Can I="create" a="products">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )

  return (
    <Can I="findAll" a="products">
      <List filters={productsFilters} actions={<ListActions />}>
        <Datagrid
          rowClick={ability.can('update', 'products') ? 'edit' : false}
          bulkActionButtons={
            <>
              <BulkExportButton />
              <Can I="delete" a="products">
                <BulkDeleteButton mutationOptions={{ onMutate: refresh }} />
              </Can>
            </>
          }
        >
          <TextField source={EProduct.Id} />

          <WrapperField label="Product - English Name" sortBy={EProduct.EnName}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ImageField
                source={EProduct.Logo}
                sx={{
                  '& img': {
                    maxWidth: 50,
                    maxHeight: 50,
                    objectFit: 'cover !important',
                    borderRadius: '50%',
                  },
                  display: 'flex',
                  marginRight: '8px',
                }}
                sortable={false}
              />
              <TextField source={EProduct.EnName} />
            </div>
          </WrapperField>
          <TextField source={EProduct.ArName} label="Product - Arabic Name" />

          <ChipField
            sx={{
              backgroundColor: colors.accent.pink,
              color: colors.neutral[0],
            }}
            source={EProduct.Type}
            label="Type"
            sortable={false}
          />
          <LabelField source={EProduct.Provider} sortable={false} />
          <WrapperField label="Brand">
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <TextField source={`${EProduct.Brand}.${EBrand.EnName}`} label="English Name" sortable={false} />
              <TextField source={`${EProduct.Brand}.${EBrand.ArName}`} label="Arabic Name" sortable={false} />
            </Box>
          </WrapperField>

          <NumberField source={`${EProduct.Brand}.${EBrand.Id}`} textAlign="left" label="Brand Id" sortable={false} />

          <WrapperField label="Redeem Instructions English">
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <TextField source={EProduct.EnRedeemInstructions} sortable={false} />
            </Box>
          </WrapperField>

          <WrapperField label="Redeem Instructions Arabic">
            <Box sx={{ display: 'flex', alignItems: 'left', flexDirection: 'column' }}>
              <TextField source={EProduct.ArRedeemInstructions} sortable={false} />
            </Box>
          </WrapperField>
          <NumberField source={EProduct.MarketPlaceCount} label="Activated in Marketplaces" textAlign="left" sortable />

          <BooleanField source={EProduct.IsActive} sortable={false} />
          <NumberField source={EProduct.Order} sortable />
          <DateField source={EProduct.CreatedAt} sortable={false} />
          <DateField source={EProduct.UpdatedAt} sortable={false} />
        </Datagrid>
      </List>
    </Can>
  )
}
