import { NO_WHITESPACE } from '@/consts/regexp'
import {
  ArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  WithRecord,
  regex,
  required,
} from 'react-admin'
import { choices, validatePhone, optionText, optionTextBrandsOrProducts, promoCodeChoices, platformChoises } from './consts'
import { EPlatformType, EPromoCodeType, EPromocode } from './types'
import { useState } from 'react'
const promoCodeTypeToReference = {
  BRAND: 'brands',
  PRODUCT: 'products',
  VARIANT: 'variants',
}

export const PromocodesEdit = () => {
  const [selectedPromoCodeType, setSelectedPromoCodeType] = useState('brands')
  const [typeIds, setTypeIds] = useState([])

  const handlePromoCodeTypeChange = (event: any) => {
    const selectedType: EPromoCodeType = event.target.value

    setSelectedPromoCodeType(promoCodeTypeToReference[selectedType])
  }

  const handleTypeIdsChange = (value: any) => {
    setTypeIds(value)
  }

  const isPromoCodeTypeDisabled = typeIds.length > 0


  function getRefereceType(record: any) {
    switch (record[EPromocode.PromoCodableType]) {
      case EPromoCodeType.BRAND:
        return 'brands'
      case EPromoCodeType.PRODUCT:
        return 'products'
      case EPromoCodeType.VARIANT:
        return 'variants'

      default:
        return 'null'
    }
  }
  return (
    <Edit mutationMode="pessimistic">
      <SimpleForm sanitizeEmptyValues>
        <TextInput
          validate={[regex(NO_WHITESPACE, 'No leading spaces allowed')]}
          source={EPromocode.Code}
          fullWidth
          required
        />
        <NumberInput source={EPromocode.Value} fullWidth required min={1} />
        <SelectInput
          source={EPromocode.ValueType}
          choices={choices}
          validate={required()}
          optionValue="name"
          fullWidth
        />
        <NumberInput source={EPromocode.MaxUsage} fullWidth required min={1} />
        <DateTimeInput source={EPromocode.ExpiryDate} fullWidth required />
        <FormDataConsumer>
          {({ formData }) => (
            <ArrayInput source={EPromocode.ConsumerPhones}>
              <SimpleFormIterator disableClear disableAdd={formData[EPromocode.ConsumerPhones]?.length > 14}>
                <TextInput source="" validate={validatePhone} sx={{ minWidth: '375px' }} placeholder="+xy..." />
              </SimpleFormIterator>
            </ArrayInput>
          )}
        </FormDataConsumer>
        <DateTimeInput source={EPromocode.MaxRegisterDate} fullWidth required />
        <BooleanInput source={EPromocode.IsActive} fullWidth />
        <BooleanInput source={EPromocode.NeverActiveUsers} fullWidth />
        <SelectInput
          source={EPromocode.PromoCodableType}
          choices={promoCodeChoices}
          validate={required()}
          optionValue="name"
          fullWidth
          onChange={handlePromoCodeTypeChange}
          defaultValue={EPromoCodeType.BRAND}
          disabled={true}
        />
        <WithRecord
          label="Type"
          render={(record) => (
            <ReferenceArrayInput source={EPromocode.PromoCodableIds} reference={getRefereceType(record)} perPage={100}>
              <AutocompleteArrayInput
                optionText={
                  record[EPromocode.PromoCodableType] != EPromoCodeType.VARIANT
                    ? optionTextBrandsOrProducts
                    : optionText
                }
                fullWidth
                onChange={handleTypeIdsChange}
              />
            </ReferenceArrayInput>
          )}
        />
        <SelectInput
            source={EPromocode.Platform}
            choices={platformChoises}
            validate={required()}
            optionValue="name"
            fullWidth
            defaultValue={EPlatformType.CONSUMER_APP}
          />
      </SimpleForm>
    </Edit>
  )
}
