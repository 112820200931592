import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  TextField,
  SimpleFormIterator,
  NumberInput,
  Toolbar,
  DeleteButton,
  SaveButton,
} from 'react-admin'
import { EStadium } from './types'
import { Can } from '@/context/AbilityContext'

export const StadiumsEdit = () => {
  const StadiumsEditToolbar = () => (
    <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Can I="update" a="stadiums">
        <SaveButton />
      </Can>
      <Can I="remove" a="stadiums">
        <DeleteButton />
      </Can>
    </Toolbar>
  )
  return (
    <Edit>
      <SimpleForm toolbar={<StadiumsEditToolbar />}>
        <TextInput source={EStadium.ArName} required />
        <TextInput source={EStadium.KuName} required />
        <TextInput source={EStadium.ChartKey} required fullWidth />
        <ArrayInput source={EStadium.Categories}>
          <SimpleFormIterator disableClear disableRemove disableAdd disableReordering fullWidth>
            <TextField source="label" />
            <NumberInput source="price" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}
