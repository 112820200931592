import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  DateField,
  SearchInput,
  BulkDeleteButton,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'

import { ETeam } from '@/modules/Teams/types'
import { ESortOrder } from '@/types/data'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'

const teamsFilters = [
  <SearchInput source={ETeam.ArNameLike} alwaysOn key={1} placeholder="Search (Arabic name)" />,
  <SearchInput source={ETeam.KuNameLike} alwaysOn key={2} placeholder="Search (Kurdish name)" />,
]

export const TeamsList = () => {
  const ListActions = () => (
    <TopToolbar>
      <Can I="create" a="teams">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )
  return (
    <Can I="findAll" a="teams">
      <List
        filters={ability.can('findOne', 'teams') ? teamsFilters : []}
        sort={{ field: ETeam.ArName, order: ESortOrder.Asc }}
        actions={<ListActions />}
      >
        <Datagrid
          rowClick={ability.can('update', 'teams') ? 'edit' : false}
          bulkActionButtons={
            <>
              <Can I="remove" a="teams">
                <BulkDeleteButton />
              </Can>
            </>
          }
        >
          <NumberField source={ETeam.Id} textAlign="left" sortable />
          <ImageField
            source={ETeam.Logo}
            sx={{
              '& img': {
                maxWidth: 50,
                maxHeight: 50,
                objectFit: 'cover !important',
                borderRadius: '50%',
              },
              display: 'flex',
              marginRight: '8px',
            }}
            sortable={false}
          />
          <TextField source={ETeam.ArName} label="Arabic Name" sortBy="arName" sortable />
          <TextField source={ETeam.KuName} label="Ku Name" sortBy="kuName" sortable />
          <DateField source={ETeam.CreatedAt} sortable />
        </Datagrid>
      </List>
    </Can>
  )
}
