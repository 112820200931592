import { AbilityClass, PureAbility, AbilityBuilder } from '@casl/ability'
import { Permission } from './AbilityContext'

export type AppAbility = PureAbility<[string, string]>

export const ability = new PureAbility<[string, string]>()

export function updateAbility(permissions: Permission[]) {
  const { can, rules } = new AbilityBuilder<PureAbility<[string, string]>>(PureAbility as AbilityClass<AppAbility>)
  permissions.forEach((permission) => {
    can(permission.action, permission.subject)
  })
  ability.update(rules)
}
