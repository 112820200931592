import { createContext, useEffect, useState } from 'react'
import { createContextualCan } from '@casl/react'
import { axiosAuthInstance } from '@/services/api/axios'
import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { ability, updateAbility } from './ability'
import { AppAbility } from '@/services/casl/defineAbility'

export interface Permission {
  subject: string
  action: string
}

interface AbilityProviderProps {
  children: React.ReactNode
}

export const AbilityContext = createContext<AppAbility | undefined>(undefined)
export const Can = createContextualCan(AbilityContext.Consumer as any)

export const AbilityProvider = ({ children }: AbilityProviderProps) => {
  const adminId = localStorage.getItem('ADMIN_ID')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const fetchUserPermissions = async (adminId: string) => {
    setLoading(true)
    setError(null)
    try {
      const response = await axiosAuthInstance.get<{ userPermissions: Permission[] }>(
        `${API_AUTH_BASE_URL}/dashboard/admins/${adminId}/permissions`,
      )
      updateAbility(response.data.userPermissions)
    } catch (err) {
      setError('Failed to fetch user permissions')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (adminId) {
      fetchUserPermissions(adminId as string)
    }
  }, [adminId])

  // if (loading) return <div>Loading...</div>
  // if (error) return <div>{error}</div>

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
}
