import { PermissionType } from '@/modules/Admins/AdminsEdit'
import { Switch, FormControlLabel, Typography, Box, Grid } from '@mui/material'

type SubjectPermissionsProps = {
  subjectTitle: string
  permissions: {
    subject: string
    action: string
  }[]
  handlePermissionToggle: (permission: { subject: string; action: string }, isToggled: boolean) => void
  checkAbility: (permission: { subject: string; action: string }) => boolean
  getPermissionType?: (permission: { subject: string; action: string }, checked: boolean) => string
}

const colorMap: { [key: string]: string } = {
  Inherited: 'green',
  Denied: 'red',
  Extended: 'black',
}

export function SubjectPermissions({
  subjectTitle,
  permissions,
  handlePermissionToggle,
  checkAbility,
  getPermissionType,
}: SubjectPermissionsProps) {
  return (
    <Box sx={{ m: 2, p: 2, width: 500, border: 1, borderRadius: 2, borderColor: 'divider' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
          p: 1,
          bgcolor: 'grey.100',
          borderRadius: 2,
        }}
      >
        <Typography variant="h6">{subjectTitle}</Typography>
      </Box>
      <Grid container spacing={2}>
        {permissions.map((perm, index) => {
          const permissionType = getPermissionType
            ? getPermissionType(perm, checkAbility(perm))
            : PermissionType.Default
          return (
            <Grid item xs={6} key={`${subjectTitle}-${perm}-${index}`}>
              <div style={{ position: 'relative' }}>
                <sup
                  style={{
                    width: '50px',
                    position: 'absolute',
                    transform: 'translate(298%, 0%)',
                    padding: '2px 6px',
                    fontSize: '8px',
                    borderRadius: '4px',
                    backgroundColor: colorMap[permissionType],
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  {permissionType === PermissionType.Default ? '' : permissionType}
                </sup>
              </div>
              <FormControlLabel
                style={
                  permissionType === PermissionType.Default
                    ? { maxWidth: '220px', width: '210px', fontSize: '10px', padding: '5px' }
                    : {
                        border: '1px',
                        borderColor: colorMap[permissionType],
                        borderStyle: 'solid',
                        borderRadius: '5px',
                        padding: '5px',
                        maxWidth: '220px',
                        width: '210px',
                        fontSize: '10px',
                      }
                }
                control={
                  <Switch
                    checked={checkAbility(perm)}
                    onChange={(e) => handlePermissionToggle(perm, e.target.checked)}
                  />
                }
                label={perm.action}
              />
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
